import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/zh';

const Services = (props) => (
  <Layout bodyClass="page-services" location={props.location}>
    <SEO title="Our Services"/>
    <div className="intro heading">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-2">我們的服務</h1>
            <p className="text-center">邁升企業顧問致力為我們的客戶—從初創公司到跨國企業—提供量身定制的專業服務。</p>
          </div>
        </div>
      </div>
    </div>

    <div className="container p-2 p-md-5 services justify-content-center">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center mb-4 text-dark">
            我們的業務線
          </h3>
        </div>
        <div className="col-4 services-card mb-3">
          <div className="services-bubble advisory">
            <h4>商業咨詢</h4>
          </div>
        </div>
        <div className="col-4 services-card mb-3">
          <div className="services-bubble tax">
            <h4>稅務</h4>
          </div>
        </div>
        <div className="col-4 services-card mb-3">
          <div className="services-bubble strategy">
            <h4>營運策略</h4>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className="goals mb-4 mb-md-8">
        <p className="text-dark">
          我們助力企業機構把握機遇，同時協助他們達到監管機構要求，確保投資者了解企業狀況，並滿足利益相關者的需要。在瞬息萬變的時代中，我們致力為企業提供他們所需要的一切支持，讓他們有效經營、創造長期增長。
        </p>
        <div className="goals-hero growth"/>
      </div>

      <div className="goals mb-4 mb-md-8">
        <p className="text-dark flip">
          邁升企業顧問致力发揮團隊的創造力、經驗、判斷力以及多元視角，運用跨領域的專業知識、多角度的分析洞察，為客戶提供專業服務。無論客戶在世界何處經營，我們都能針對客戶的挑戰提出解決方案，助他們的業務順利適應數碼化未來。
        </p>
        <div className="goals-hero digitalisation flip"/>
      </div>
    </div>
  </Layout>
);

export default Services;
